import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, Popover } from 'antd';

import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

import { getPermissions } from '@/auth/redux/reducer';
import OffersActions from '@/containers/Offers/redux/reducer';

const columns = [
  {
    title: '',
    dataIndex: 'checkbox',
    key: 'checkbox',
    width: '0.1%',
  },
  {
    title: 'Loja',
    dataIndex: 'local_name',
  },
  {
    title: 'Ações',
    dataIndex: 'gifts',
    render: (data) => {
      const text = data?.join(', ');
      return (
        <div>
          <span>{text?.substring(0, 40).concat(text.length >= 40 ? '...' : '')}</span>
        </div>
      );
    },
  },
  {
    title: 'Supervisor',
    dataIndex: 'user_name',
  },
  {
    title: (
      <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
        <div>Data de criação{' '}</div>
        <Popover
          content="Data utilizada para o filtro de intervalo de datas."
          placement="bottom"
          trigger="hover"
        >
           <InfoCircleOutlined style={{ fontSize: 18, marginLeft: 5 }} />
          </Popover>
      </div>
    ),
    dataIndex: 'scheduled_date',
    width: '10%',
    render: (text, record) => (
      <div>
        <span>{moment(text).format('DD/MM/YYYY HH:mm')}</span>
      </div>
    ),
  },
  {
    title: 'Início',
    dataIndex: 'scheduled_begin',
    width: '10%',
    render: (text, record) => (
      <div>
        <span>{moment(text).format('DD/MM/YYYY')}</span>
      </div>
    ),
  },
  {
    title: 'Fim',
    dataIndex: 'scheduled_end',
    width: '10%',
    render: (text, record) => (
      <div>
        <span>{moment(text).format('DD/MM/YYYY')}</span>
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'statusTag',
    width: '10%',
  },
  {
    title: '',
    dataIndex: 'link_answers',
    width: '3%',
    render: (text, record) => (
      <Link to={`/offers/${record.id}`} target="_blank">
        <LinkOutlined />
      </Link>
    ),
  },
];

const ManagementTable = ({ data }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const permissions = useSelector(getPermissions);

  const dispatch = useDispatch();
  const offerPendingStatuses = ['pending'];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
      dispatch(OffersActions.setOfferIds(selectedKeys));
    },
    getCheckboxProps: (record) => ({
      disabled:
        !(record.status !== 'pending' && _.has(permissions, 'validate_offer')) &&
        !offerPendingStatuses.includes(record.status_offer || record.status),
    }),
  };

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{
        scrollToFirstRowOnChange: false,
        y: 500,
      }}
    />
  );
};

export default ManagementTable;
